import {useMutation, useQuery} from '@apollo/client';
import {FieldValues, useForm} from 'react-hook-form';
import {useCallback, useEffect, useState} from 'react';
import {navigate, PageProps} from 'gatsby';
import {
    DeletePlaylistDocument,
    EditPlaylistDocument,
    GetPlaylistDocument,
    GetPlaylistQuery,
    GetChangelogDocument,
} from '../../../../graphql-types';
import {getPlaylistTabsData} from '../../../../configs/pages/playlists/tabs';
import {Layout, StatusBar} from '../../../../components/layout';
import {
    Button, Form, Input, LoadingIndicator, SelectBox, Tabs, Textarea, WarningPopup,
} from '../../../../components/core';
import isDirtyForm from '../../../../helpers/form/isDirtyForm';
import pickDirtyFields, {convertToBoolean} from '../../../../helpers/form/pickDirtyFields';
import * as layoutStyles from '../../../../components/layout/layout.module.css';
import {DeleteIcon, NavArrowLeftIcon} from '../../../../components/core/icons';
import toastify, {ToastLocationState} from '../../../../helpers/toast/toastify';

const getPlaylistValues = (data: GetPlaylistQuery) => ({
    name: data.playlist?.name,
    languageId: data.playlist?.language.id,
    youtubeId: data.playlist?.youtubeId,
    channelId: data.playlist?.channel.id,
    customThumbnail: data.playlist?.customThumbnail ? 'true' : 'false',
    description: data.playlist?.description,
    privacy: data.playlist?.privacy,
    themeId: data.playlist?.theme?.id,
    typeId: data.playlist?.type?.id,
    note: data.playlist?.note,
});

type PlaylistEditPageType = {
    playlist: string,
};

type PlaylistEditPageTypeProps = PageProps<null, null, ToastLocationState> & PlaylistEditPageType;

const PlaylistEditPage = ({
    playlist: playlistId,
    location,
}: PlaylistEditPageTypeProps) => {
    const {loading, data: playlistData} = useQuery(GetPlaylistDocument, {variables: {playlistId}});
    const playlistTabsData = getPlaylistTabsData();
    const [editPlaylist, {loading: submittingEdit}] = useMutation(EditPlaylistDocument);
    const [deletePlaylist, {loading: submittingDelete}] = useMutation(DeletePlaylistDocument);
    const {
        control,
        reset,
        handleSubmit,
        formState: {
            dirtyFields,
        },
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {},
    });
    const [discardWarningDisplayed, setDiscardWarningDisplayed] = useState(false);
    const [onDiscardWarningConfirm, setOnDiscardWarningConfirm] = useState<() => () => void>(() => () => {});
    const [deleteWarningDisplayed, setDeleteWarningDisplayed] = useState(false);
    const [onDeleteWarningConfirm, setOnDeleteWarningConfirm] = useState<() => () => void>(() => () => {});
    const isDirty = isDirtyForm(dirtyFields);
    const playlistTitle = playlistData ? playlistData.playlist?.name : playlistId;
    const pageTitle = `Playlist | ${playlistTitle}`;

    const interceptLinks = {
        status: !!(isDirty),
        action: () => {
            setDiscardWarningDisplayed(true);
        },
        setOnConfirm: setOnDiscardWarningConfirm,
    };

    useEffect(() => {
        if (!playlistData) {
            return;
        }

        reset(getPlaylistValues(playlistData), {keepDirtyValues: false});
    }, [reset, playlistData]);

    const onSubmit = (formData: FieldValues) => {
        const newData = pickDirtyFields(formData, dirtyFields);

        if (newData.customThumbnail !== undefined) {
            newData.customThumbnail = convertToBoolean(newData.customThumbnail);
        }

        editPlaylist({
            variables: {
                playlistId,
                input: newData,
            },
            onCompleted(editData) {
                if (!editData) {
                    toastify({
                        type: 'error',
                        text: 'There was an error on playlist update',
                    });

                    return;
                }

                toastify({
                    type: 'success',
                    text: 'Playlist basic info updated',
                });
            },
            onError(error) {
                console.log(error);
            },
            refetchQueries: [
                {
                    query: GetChangelogDocument,
                    variables: {
                        filter: {
                            playlistIds: [playlistId],
                        },
                    },
                },
            ],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    const handleDeletePlaylist = useCallback(() => {
        setOnDeleteWarningConfirm(() => () => {
            deletePlaylist({
                variables: {
                    deletePlaylistId: playlistId,
                },
                onCompleted() {
                    setDeleteWarningDisplayed(false);

                    navigate('../', {
                        state: {
                            toast: {
                                type: 'success',
                                text: 'Playlist deleted',
                            },
                        },
                    });
                },
                onError(err) {
                    toastify({
                        type: 'error',
                        text: `There was an error on playlist delete: ${err.message}`,
                    });
                },
                onQueryUpdated(observableQuery) {
                    return observableQuery.refetch();
                },
            });
        });

        setDeleteWarningDisplayed(true);
    }, [deletePlaylist, playlistId]);

    useEffect(() => {
        if (!location?.state?.toast) {
            return;
        }

        toastify(location.state.toast);
        window.history.replaceState(null, '');
    }, [location?.state?.toast]);

    return (
        <>
            <Layout
                title={pageTitle}
                interceptLinks={interceptLinks}
                scope="playlists.write"
            >
                {loading && (
                    <LoadingIndicator/>
                )}
                <div className={layoutStyles.pageHeader}>
                    <StatusBar
                        buttons={(
                            <>
                                <Button
                                    icon={NavArrowLeftIcon}
                                    text="Back to playlists"
                                    onClick={() => {
                                        const to = '../';

                                        if (isDirty) {
                                            setOnDiscardWarningConfirm(() => () => {
                                                navigate(to);
                                            });
                                            setDiscardWarningDisplayed(true);

                                            return;
                                        }

                                        navigate(to);
                                    }}
                                    variant="outline"
                                />
                                <Button
                                    text="Save changes"
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={!isDirty || submittingEdit === true}
                                    loading={submittingEdit === true}
                                />
                            </>
                        )}
                        title={(
                            <>
                                <span>Playlist /&nbsp;</span>
                                <strong>{playlistTitle}</strong>
                            </>
                        )}
                    />
                </div>
                <div>
                    <Tabs
                        data={playlistTabsData}
                    />
                </div>
                <div className={layoutStyles.pageContent}>
                    {playlistData && (
                        <>
                            <h2>Basic info</h2>
                            <Form>
                                <Form.Row>
                                    <Form.Column>
                                        <Input
                                            title="Name"
                                            name="name"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Form.Column>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Column>
                                        <SelectBox<Record<string, string>>
                                            title="Language"
                                            placeholder="Choose the language"
                                            control={control}
                                            controllerName="languageId"
                                            optionsValues={
                                                playlistData.languages?.map(language => ({
                                                    id: language?.id,
                                                    value: language?.id,
                                                    label: language?.shortName,
                                                }))
                                            }
                                            isDisabled={true}
                                        />
                                    </Form.Column>
                                    <Form.Column>
                                        <SelectBox<Record<string, string>>
                                            title="Channel"
                                            placeholder="Choose the channel"
                                            control={control}
                                            controllerName="channelId"
                                            optionsValues={
                                                playlistData.channels.map(channel => ({
                                                    id: channel?.id,
                                                    value: channel?.id,
                                                    label: channel?.name,
                                                }))
                                            }
                                            isDisabled={true}
                                        />
                                    </Form.Column>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Column>
                                        <Input
                                            title="ID"
                                            name="youtubeId"
                                            control={control}
                                        />
                                    </Form.Column>
                                    <Form.Column>
                                        <SelectBox<Record<string, string>>
                                            title="Thumbnail"
                                            placeholder=""
                                            control={control}
                                            controllerName="customThumbnail"
                                            optionsValues={[
                                                {
                                                    id: '1',
                                                    value: 'true',
                                                    label: 'Yes',
                                                }, {
                                                    id: '0',
                                                    value: 'false',
                                                    label: 'No',
                                                },
                                            ]}
                                        />
                                    </Form.Column>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Column>
                                        <Textarea
                                            control={control}
                                            name="description"
                                            title="Description"
                                        />
                                    </Form.Column>
                                    <Form.Column>
                                        <SelectBox<Record<string, string>>
                                            title="Privacy"
                                            placeholder=""
                                            control={control}
                                            controllerName="privacy"
                                            optionsValues={
                                                playlistData.privacyAttributes.map(privacy => ({
                                                    id: privacy?.name,
                                                    value: privacy?.name,
                                                    label: `${privacy?.name.charAt(0).toUpperCase()}${privacy?.name.slice(1)}`,
                                                }))
                                            }
                                        />
                                        <SelectBox<Record<string, string>>
                                            title="Theme"
                                            placeholder="Choose the theme"
                                            control={control}
                                            controllerName="themeId"
                                            optionsValues={
                                                playlistData.themeAttributes.map(theme => ({
                                                    id: theme?.id,
                                                    value: theme?.id,
                                                    label: theme?.name,
                                                }))
                                            }
                                            canType={true}
                                        />
                                    </Form.Column>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Column hasHalfWidth={true}>
                                        <SelectBox<Record<string, string>>
                                            title="Playlist type"
                                            placeholder="Choose the playlist type"
                                            control={control}
                                            controllerName="typeId"
                                            optionsValues={
                                                playlistData.playlistTypes.map(type => ({
                                                    id: type?.id,
                                                    value: type?.id,
                                                    label: type?.name,
                                                }))
                                            }
                                        />
                                    </Form.Column>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Column>
                                        <Textarea
                                            control={control}
                                            name="note"
                                            title="Notes"
                                        />
                                    </Form.Column>
                                </Form.Row>
                            </Form>
                            <div className={layoutStyles.pageSection}>
                                <h3>Danger zone</h3>
                                <Button
                                    text={'Delete playlist'}
                                    color="error"
                                    onClick={handleDeletePlaylist}
                                    disabled={submittingDelete}
                                    icon={DeleteIcon}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Layout>
            {discardWarningDisplayed && (
                <WarningPopup
                    topText={[
                        'Whoa, wait!',
                        <br key="linebreak" />,
                        'You are about to discard all the changes. Do you really want to do that?',
                    ]}
                    buttons={(
                        <>
                            <Button
                                text="Yes, discard all changes"
                                onClick={onDiscardWarningConfirm}
                                color={'error'}
                            />
                            <Button
                                text="No, take me back to editing"
                                onClick={() => {
                                    setDiscardWarningDisplayed(false);
                                }}
                            />
                        </>
                    )}
                />
            )}
            {deleteWarningDisplayed && (
                <WarningPopup
                    topText={'Do you really want to delete the playlist?'}
                    buttons={(
                        <>
                            <Button
                                text="Yes, delete it"
                                onClick={onDeleteWarningConfirm}
                                color={'error'}
                                disabled={submittingDelete}
                                loading={submittingDelete}
                            />
                            <Button
                                text="No, keep it"
                                onClick={() => {
                                    setDeleteWarningDisplayed(false);
                                }}
                            />
                        </>
                    )}
                />
            )}
        </>
    );
};

export default PlaylistEditPage;
